<template>
  <div class="dll-logs view">
    <app-header :title="'Dll Logs'"/>
    <progress-linear v-if="loading" :indeterminate="true"/>
    <div class="control-bar" :class="{'control-bar--disabled': loading}">
      <div class="control-bar__info">
        <div class="control-bar__info-title">{{__('common.total')}}:</div>
        <div class="control-bar__info-value">{{ pagination.total }}</div>
      </div>
      <text-field
          class="control-bar__search"
          v-model="searchTerm"
          :label="__('common.search_term')"
          max-length="50"
          :hide-details="true"
          :clearable="true"
          @keyup:enter="refreshDelayed(100)"
          @click:clear="refreshDelayed(100)"
      />
      <div class="control-bar__buttons">
        <div class="control-bar__button" :title="__('common.refresh')" @click="refresh" v-slashes>
          <img class="control-bar__button-icon" src="https://cdn.hyperion-corporation.de/ui/svgs/refresh.svg"
               alt="refresh"/>
        </div>
      </div>
    </div>
    <div class="scroll-horizontal-overflow">
      <pagination
          class="mt"
          :count-pages="pagination.pages"
          :total-visible="10"
          :page="pagination.page"
          :disabled="loading"
          @paginate="paginate"
      />
      <data-table
          :records="dllLogs"
          :fields="dataTableFields"
          :record-actions="recordActions"
          :sort-by="sortBy"
          :sort-desc="sortDesc"
          :loading="loading"
          @sort="sort"
      />
      <pagination
          class="mt"
          :count-pages="pagination.pages"
          :total-visible="10"
          :page="pagination.page"
          :disabled="loading"
          @paginate="paginate"
      />
    </div>
  </div>
</template>

<script>
  import apiSecured from '../../api/secured';
  import DataTable from '../../components/DataTable'
  import AppHeader from '../../components/AppHeader'
  import { notificationsMixin } from '../../mixins/notifications';
  import { localizationMixin } from '../../mixins/localization';
  import { paginationMixin } from "../../mixins/pagination";
  import { pageTitleMixin } from "../../mixins/pageTitle";

  export default {
    mixins: [notificationsMixin, localizationMixin, paginationMixin, pageTitleMixin],
    components: {
      appHeader: AppHeader,
      dataTable: DataTable,
    },
    data: () => ({
      loading: false,
      dllLogs: [],
      dataTableFields: [],
      recordActions: [],
    }),
    methods: {
      refreshDelayed(delay) {
        setTimeout(this.refresh, delay);
      },
      refresh() {
        this.loading = true;
        apiSecured.get('/dll/dlllog', {
          params: this.getPaginationParams(),
        }).then(async (res) => {
          this.dllLogs = res.data;
          await this.enrichRecords();
          this.updatePagination(res);
          this.loading = false;
          this.showInfoNotification('Dll Logs have been reloaded');
        }).catch((error) => {
          this.loading = false;
          this.showErrorNotifications(error)
        });
      },
      async enrichRecords() {
        const playerIds = this.dllLogs.filter(record => record.playerId !== null).map(record => record.playerId);

        if (playerIds.length > 0) {
          await apiSecured.get(`/ingame/civilization/player/(${playerIds.join(',')})`, {
            params: {
              fields: 'id,name'
            },
          }).then((res) => {
            this.dllLogs = this.dllLogs.map(record => {
              record.player = res.data.find(resRecord => resRecord.id === record.playerId);
              return record;
            });
            this.showInfoNotification('Players have been reloaded');
          }).catch((error) => {
            this.showErrorNotifications(error)
          });
        }
        this.dllLogs = this.dllLogs.map(record => {
          record.position = {
            x: record.positionX,
            y: record.positionY,
            z: record.positionZ,
          };
          return record;
        });
      },
    },
    created() {
      this.setPageTitle('Dll Logs');
      this.dataTableFields = [
        { name: "id", title: this.__('entity.common.id'), type: "default" },
        { name: "version", title: 'Dll Version', type: "default" },
        { name: "playerId", title: 'Player Id', type: "default" },
        { name: "player.name", title: 'Player Name', type: "ref", disableSorting: true },
        { name: "position", title: 'Position', type: "vec3", disableSorting: true },
        { name: "description", title: 'Description', type: "default" },
        { name: "createdAt", title: this.__('entity.common.created_at'), type: "datetime" },
        { name: "updatedAt", title: this.__('entity.common.updated_at'), type: "datetime" }
      ];
      this.recordActions = [];
      this.refresh();
    }
  }
</script>
